import React, { PureComponent } from 'react';
import { store } from '../../../../App/store';
import { Card, Col } from 'reactstrap';
import LoadingIcon from '../../../../../shared/LoadingIcon';
import { fetchEmbed } from '../../../../../utils/helpers';
const embedItem = 'o2ALA_WebBehavior';
let srcDoc = '';

class Content extends PureComponent {
  static contextType = store;

  constructor(props){
    super(props);
    this.state = {
      loading: false,
      loaded: false,
      iframeHeight: '100%',
      containerHeight: 'calc(100vh - 150px)',
    }
    this.containerRef = React.createRef();
  }

  componentDidMount = async () => {
    const globalState = this.context;
    const { userGroup } = globalState.state;
    const isAdmin = userGroup === 'Admin';
    const position = this.containerRef.current.getBoundingClientRect();
    const topPosition = position.top;
    this.setState({
      loading: true,
      containerHeight: `calc(100vh - ${topPosition}px)`
    });

    this.fetchEmbed = fetchEmbed.bind(this);
    srcDoc = await this.fetchEmbed(embedItem, '', isAdmin);

    this.setState({ // prevState - previous state 
      loading: false, 
      loaded: true
    });

  }

  render(){
    return (
      <Col md={12}>
        <Card>
            <LoadingIcon />
            <div id="iframe-container" className="iframe-container" ref={this.containerRef} style={{height: this.state.containerHeight}}>
              <div className="iframe-wrapper" style={{height: this.state.iframeHeight}}>
                {this.state.loaded ? (
                  <iframe className="iframe__web" title="Web embed" id="iframe" src="" srcDoc={srcDoc} width="100%" height="100%" scrolling="no"></iframe>
                ) : null}
              </div>
            </div>
        </Card>
      </Col>
    )
}}
  
  export default Content;

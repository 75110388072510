import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { store } from '../App/store';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const AuthReturn = () => {
    const globalState = useContext(store);
    const { dispatch } = globalState;
    const { authRedirect } = globalState.state;

    let locationSearch = window.location.search.substring(1)
    let params = new URLSearchParams(locationSearch);
    let eqReg = /%3D/gi;

    let username = params.get("adu");
    username = username.replace(eqReg,'=');
    username = atob(username);

    let userGroup = params.get("aug");

    let userE = params.get("adue");
    userE = userE.replace(eqReg,'=');
    //let userEmail = atob(userE);

    let accountName = params.get("adn");
    accountName = accountName.replace(eqReg,'=');
    accountName = atob(accountName);

    // let teamName =params.get("agt");
    // teamName = JSON.parse(teamName);

    // let usersTeam =params.get("aua");
    // usersTeam = JSON.parse(usersTeam);
    let domoToken = params.get("domoToken");
    domoToken = domoToken.replace(eqReg,'=');
    // domoToken = atob(domoToken);
    // TODO return Account ID from Azure AD
    let accountID = params.get("adai");

    let accountDefined = accountID === "multiple" ? false : true;
    accountID = accountID.replace(eqReg,'=');
    accountID = atob(accountID);

    let pgWebsite = params.get("pgweb");

    let timeoutDuration = params.get("timeout") !== null ? Number(params.get("timeout")) : userGroup !== 'Client' ? 2 : 1;

    let sicSurveyUrl = params.get("sic_survey_url") !== null ? params.get("sic_survey_url") : '';

    //let sicSurveyPopup = typeof cookies.get('sicSurveyPopup') !== 'undefined' ? cookies.get('sicSurveyPopup') : '';

    useEffect(() => {
        // dispatch({ type: 'SET_ACCOUNT_TEAM', value: teamName[0] });
        // dispatch({ type: 'SET_ACCOUNT_USERS', value: usersTeam });

        sessionStorage.setItem('isAuthenticated', true);
        sessionStorage.setItem('user', username);
        sessionStorage.setItem('userE', userE);
        sessionStorage.setItem('userGroup', userGroup);
        sessionStorage.setItem('domoToken', domoToken);
        window.domoToken = domoToken;
        window.__RYUU_AUTHENTICATION_TOKEN__ = domoToken;
        cookies.set('isAuthenticated', true, {path: '/', secure: true, sameSite: 'strict'})
        cookies.set('user', username, {path: '/', secure: true, sameSite: 'strict'})
        cookies.set('userGroup', userGroup, {path: '/', secure: true, sameSite: 'strict'})
        cookies.set('userE', userE, {path: '/', secure: true, sameSite: 'strict'})
        cookies.set('domoToken', domoToken, {path: '/', secure: true, sameSite: 'strict'})
        cookies.set('timeoutDuration', timeoutDuration, {path: '/', secure: true, sameSite: 'strict'})
        cookies.set('sicSurveyUrl', sicSurveyUrl, {path: '/', secure: true, sameSite: 'strict'})
        // if(sicSurveyPopup === '') {
        //     sicSurveyPopup = 'center';
        //     let surveyCookieExpiration = new Date(Date.parse('01 Nov 2023 00:00:00 CDT'));
        //     cookies.set('sicSurveyPopup', sicSurveyPopup, {path: '/', secure: true, sameSite: 'strict', expires: surveyCookieExpiration})
        // }
        
        dispatch({ type: 'SET_USER', value: username });
        dispatch({ type: 'SET_USER_EMAIL', value: userE });
        dispatch({ type: 'SET_USER_GROUP', value: userGroup });
        dispatch({ type: 'SET_USER_AUTH', value: true });
        dispatch({ type: 'SET_EMBED_TOKEN', value: domoToken });
        dispatch({ type: 'SET_AZURE_LOGIN', value: true });
        dispatch({ type: 'SET_TIMEOUT_DURATION', value: timeoutDuration });
        dispatch({ type: 'SET_SIC_SURVEY_URL', value: sicSurveyUrl });
        //dispatch({ type: 'SET_SIC_SURVEY_POPUP', value: sicSurveyPopup });

        if(accountDefined) {
            let params = new URLSearchParams(locationSearch);
            sessionStorage.setItem('accountName', accountName);
            sessionStorage.setItem('isAzureLogin', true);
            sessionStorage.setItem('pgWebsite',pgWebsite);
            cookies.set('accountID', accountID, {path: '/', secure: true, sameSite: 'strict'})
            cookies.set('accountName', accountName, {path: '/', secure: true, sameSite: 'strict'})
            cookies.set('isAzureLogin', true, {path: '/', secure: true, sameSite: 'strict'})
            cookies.set('pgWebsite', pgWebsite, {path: '/', secure: true, sameSite: 'strict'})

            dispatch({type: 'SET_ACCOUNT_ID', value: accountID });
            dispatch({type: 'SET_ACCOUNT_NAME', value: accountName});
            dispatch({type: 'SET_PG_WEBSITE', value: pgWebsite});
            dispatch({type: 'SET_AZURE_LOGIN', value: true});
            
            let webClient = params.get("web_c") === '1' ? true : false;
            let emailClient = params.get("email_c") === '1' ? true : false;
            let surveyClient = params.get("survey_c") === '1' ? true : false;
            let customSurveyClient = params.get("custom_survey_c") === '1' ? true : false;
            let legacySurveyClient = params.get("legacy_survey_c") === '1' ? true : false;
            let givingDocsClient = params.get("giving_docs_c") === '1' ? true : false;
            let platformClient = params.get("platform_c") === '1' ? true : false;
            let fulfillmentClient = params.get("fulfill_c") === '1' ? true : false;
            let lastActivity = params.get("last_activity") !== null ? new Date(params.get("last_activity")) : '';

            cookies.set('webClient', webClient, {path: '/', secure: true, sameSite: 'strict'})
            cookies.set('emailClient', emailClient, {path: '/', secure: true, sameSite: 'strict'})
            cookies.set('surveyClient', surveyClient, {path: '/', secure: true, sameSite: 'strict'})
            cookies.set('customSurveyClient', customSurveyClient, {path: '/', secure: true, sameSite: 'strict'})
            cookies.set('legacySurveyClient', legacySurveyClient, {path: '/', secure: true, sameSite: 'strict'})
            cookies.set('givingDocsClient', givingDocsClient, {path: '/', secure: true, sameSite: 'strict'})
            cookies.set('platformClient', platformClient, {path: '/', secure: true, sameSite: 'strict'})
            cookies.set('fulfillmentClient', fulfillmentClient, {path: '/', secure: true, sameSite: 'strict'})
            cookies.set('lastActivity', lastActivity, {path: '/', secure: true, sameSite: 'strict'})
            
            dispatch({ type: 'SET_WEB_CLIENT', value: webClient });
            dispatch({ type: 'SET_EMAIL_CLIENT', value: emailClient });
            dispatch({ type: 'SET_SURVEY_CLIENT', value: surveyClient });
            dispatch({ type: 'SET_CUSTOM_SURVEY_CLIENT', value: customSurveyClient });
            dispatch({ type: 'SET_LEGACY_SURVEY_CLIENT', value: legacySurveyClient });
            dispatch({ type: 'SET_GIVING_DOCS_CLIENT', value: givingDocsClient });
            dispatch({ type: 'SET_PLATFORM_CLIENT', value: platformClient });
            dispatch({ type: 'SET_FULFILLMENT_CLIENT', value: fulfillmentClient });
            dispatch({ type: 'SET_LAST_ACTIVITY', value: lastActivity });
        }
    }, [dispatch, accountDefined, username, userE, userGroup, domoToken, accountID, accountName, pgWebsite, timeoutDuration, locationSearch, sicSurveyUrl]);

    if(accountDefined) {
        if(authRedirect !== '') {
            return (
                <Redirect to={authRedirect} />
            )
        }
        return (
            <Redirect to="/app/home" />
        )
    } else {
        return (
            <Redirect to="/account-select" />
        )
    }
};

export default AuthReturn;

import React, { createContext, useReducer } from 'react';
import Cookies from 'universal-cookie';
//import Dexie from "dexie";
//let db = new Dexie("Leads");
const cookies = new Cookies();

// const getData = (key, value, store_key) => {
//     if(localStorage.getItem('SET_SESSION_STORAGE') !== null) {
//         var data = JSON.parse(localStorage.getItem('SET_SESSION_STORAGE'));
//         Object.keys(data).forEach(key => {
//             sessionStorage.setItem(key, data[key]);
//         })
//     }
//     return sessionStorage.getItem(key);
// };
// const getDbData = async () => {
//     if(!db.isOpen()) {
//         await db.open()
//     }
// }

const getData = (key, value, store_key) => {
    return cookies.get(key);
};

const initialState = {
    theme: 'themeReducer', // TODO
    sidebarCollapse: getData('sidebarCollapse') != null && getData('sidebarCollapse') === 'true' ? true : false, // TODO
    user: getData('user') != null ? getData('user') : '',
    userE: getData('userE') != null ? getData('userE') : '',
    userGroup: getData('userGroup') != null ? getData('userGroup') : '', // Client, Stelter, Admin
    userBrowser: getData('userBrowser') != null ? getData('userBrowser') : '',
    isAuthenticated: getData('isAuthenticated') != null ? getData('isAuthenticated') : false,
    embedToken: getData('domoToken') != null ? getData('domoToken') : '',
    multipleAccounts: getData('multipleAccounts') != null ? getData('multipleAccounts') : false,
    accountID: getData('accountID') != null ? getData('accountID') : '',
    accountName: getData('accountName') != null ? getData('accountName') : '',
    accountTeam: getData('accountTeam') != null ? JSON.parse(getData('accountTeam')) : '',
    accountUsers: getData('users') != null ? JSON.parse(getData('users')) : [],
    pgWebsite: getData('pgWebsite') != null ? getData('pgWebsite') : '',
    webClient: getData('webClient') != null ? getData('webClient') : '',
    emailClient: getData('emailClient') != null ? getData('emailClient') : '',
    surveyClient: getData('surveyClient') != null ? getData('surveyClient') : '',
    customSurveyClient: getData('customSurveyClient') != null ? getData('customSurveyClient') : '',
    legacySurveyClient: getData('legacySurveyClient') != null ? getData('legacySurveyClient') : '',
    givingDocsClient: getData('givingDocsClient') != null ? getData('givingDocsClient') : '',
    platformClient: getData('platformClient') != null ? getData('platformClient') : '',
    fulfillmentClient: getData('fulfillmentClient') != null ? getData('fulfillmentClient') : '',
    timeoutDuration: getData('timeoutDuration') != null ? getData('timeoutDuration') : 1,
    lastActivity: getData('lastActivity') != null ? getData('lastActivity') : '',
    sicSurveyUrl: getData('sicSurveyUrl') != null ? getData('sicSurveyUrl') : '',
    sicSurveyPopup: getData('sicSurveyPopup') != null ? getData('sicSurveyPopup') : 'center',
    filterEmailType: 'All',
    isAzureLogin: getData('isAzureLogin') != null ? getData('isAzureLogin') : false,
    authRedirect: getData('authRedirect') != null ? getData('authRedirect') : '',
    leadsData: [],
    leadsSummaryData: [],
    leadsNameEmails: [],
    leadActivities: '',
    visitSources: '',
    totalLeads: 0
};

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case 'SET_SIDEBAR_COLLAPSE':
                return {...state, sidebarCollapse: action.value}
            case 'SET_USER':
                return {...state, user: action.value}
            case 'SET_USER_EMAIL':
                return {...state, userE: action.value}
            case 'SET_USER_GROUP':
                return {...state, userGroup: action.value}
            case 'SET_USER_BROWSER':
                return {...state, userBrowser: action.value}
            case 'SET_USER_AUTH':
                return {...state, isAuthenticated: action.value}
            case 'SET_EMBED_TOKEN':
                return {...state, embedToken: action.value}
            case 'SET_MULTIPLE_ACCOUNTS':
                return {...state, multipleAccounts: action.value}
            case 'SET_ACCOUNT_ID':
                return {...state, accountID: action.value}
            case 'SET_ACCOUNT_NAME':
                return {...state, accountName: action.value}
            case 'SET_ACCOUNT_USERS':
                return {...state, accountUsers: action.value}
            case 'SET_ACCOUNT_TEAM':
                return {...state, accountTeam: action.value}
            case 'SET_PG_WEBSITE':
                return {...state, pgWebsite: action.value}
            case 'SET_WEB_CLIENT':
                return {...state, webClient: action.value}
            case 'SET_EMAIL_CLIENT':
                return {...state, emailClient: action.value}
            case 'SET_SURVEY_CLIENT':
                return {...state, surveyClient: action.value}
            case 'SET_CUSTOM_SURVEY_CLIENT':
                return {...state, customSurveyClient: action.value}
            case 'SET_LEGACY_SURVEY_CLIENT':
                return {...state, legacySurveyClient: action.value}
            case 'SET_GIVING_DOCS_CLIENT':
                return {...state, givingDocsClient: action.value}
            case 'SET_PLATFORM_CLIENT':
                return {...state, platformClient: action.value}
            case 'SET_FULFILLMENT_CLIENT':
                return {...state, fulfillmentClient: action.value}
            case 'SET_TIMEOUT_DURATION':
                return {...state, timeoutDuration: action.value}
            case 'SET_LAST_ACTIVITY':
                return {...state, lastActivity: action.value}
            case 'SET_SIC_SURVEY_URL':
                return {...state, sicSurveyUrl: action.value}
            case 'SET_SIC_SURVEY_POPUP':
                return {...state, sicSurveyPopup: action.value}
            case 'SET_EMAIL_TYPE':
                return {...state, filterEmailType: action.value}
            case 'SET_AZURE_LOGIN':
                return {...state, isAzureLogin: action.value}
            case 'SET_AUTH_REDIRECT':
                return {...state, authRedirect: action.value}
            case 'SET_LEADS_DATA':
                return {...state, leadsData: action.value}
            case 'SET_LEADS_SUMMARY':
                return {...state, leadsSummaryData: action.value}
            case 'SET_LEADS_NAMEEMAILS':
                return {...state, leadsNameEmails: action.value}
            case 'SET_LEADS_ACTIVITIES':
                return {...state, leadActivities: action.value}
            case 'SET_VISIT_SOURCES':
                return {...state, visitSources: action.value}
            case 'SET_TOTAL_LEADS':
                return {...state, totalLeads: action.value}
            default:
                throw new Error();
        };
    }, initialState);

    return <Provider value = {{ state, dispatch }} > { children } </Provider>;
};

export {
    store,
    StateProvider
}
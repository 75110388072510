import React, { PureComponent } from 'react';
import { store } from '../../../App/store';
import { Col, Container, Row } from 'reactstrap';
import Content from './components/EmailContent';
import PageTooltip from '../../../../shared/PageTooltip';
//import Filters from './components/EmailFilters';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class Page extends PureComponent {
  static contextType = store;

  constructor(props){
    super(props);
    this.state = {
      dropdown: false,
      selection: '',
      filterChange: 1,
      refreshTime: 0
    }
  }

  componentDidMount = () => {
    const globalState = this.context;
    const { filterEmailType } = globalState.state;
    this.setState({ selection: filterEmailType });
  }

  // componentDidUpdate = () => {
  //   const globalState = this.context;
  //   const { filterEmailType } = globalState.state;
  //   let { seType, filterChange } = this.state;
  //   if(filterEmailType !== stateEmailType) {
  //     this.setState({
  //       stateEmailType: filterEmailType, 
  //       filterChange: filterChange++
  //     });
  //   }
  // }

  toggle = () => this.setState({ dropdownOpen: !this.state.dropdownOpen });

  onSelect = x => {
      const globalState = this.context;
      const { dispatch } = globalState;
      let { filterChange } = this.state;
      dispatch({ type: 'SET_EMAIL_TYPE', value: x });
      this.setState({ 
        selection: x,
        filterChange: ++filterChange
      })
  };

  setRefreshTime = x => this.setState({ refreshTime: x })

  render(){
    const { dropdownOpen, selection, filterChange, refreshTime } = this.state;

    return (
      <div className="container__wrap">
        <Container className="dashboard">
          <PageTooltip state={{ refreshTime: [refreshTime, this.setRefreshTime] }}/>
          <Row className="row__dashboard-intro">
            <Col md={12} className="dashboard-intro">
              <h3 className="page-title dashboard-title">[DEV 2.0] Email Analytics</h3>
              <p className="dashboard-subtitle">View the key metrics from your email campaigns to track engagement with your marketing efforts over time.</p>
            </Col>
            {/* Keep page-filters for testing */}
            <Col md={4} className="page-filters">
              {/* <Filters /> */}
              <ButtonDropdown isOpen={dropdownOpen} toggle={()=> this.toggle()}>
                  <DropdownToggle caret>Email Type: <span className="filter-selection__wrapper"><span className="filter-selection emailtype-selection">{selection}</span></span></DropdownToggle>
                  <DropdownMenu>
                      <DropdownItem onClick={()=> this.onSelect('All')}>All</DropdownItem>
                      <DropdownItem onClick={()=> this.onSelect('Educational')}>Educational</DropdownItem>
                      <DropdownItem onClick={()=> this.onSelect('Re-Engagement')}>Re-Engagement</DropdownItem>
                      <DropdownItem onClick={()=> this.onSelect('Survey')}>Survey</DropdownItem>
                      <DropdownItem onClick={()=> this.onSelect('Targeted')}>Targeted</DropdownItem>
                  </DropdownMenu>
              </ButtonDropdown>
            </Col>
          </Row>
          <Row>
            <Content key={refreshTime + '_' + filterChange}/>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Page;

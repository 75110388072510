import React, { useState, useContext, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { store } from '../../App/store';
import LoadingIcon from '../../../shared/LoadingIcon';
import PageTooltip from '../../../shared/PageTooltip';
import LeadsContent from './components/LeadsCustomContent';

const Page = () => {
  const globalState = useContext(store);
  const { leadActivities } = globalState.state;
  const [ refreshTime, setRefreshTime ] = useState(0);
  const [ leadsLoading, setLeadsLoading ] = useState(true);
  const [ leadsExist, setLeadsExist ] = useState(false);

  useEffect(() => {
    //console.log(leadActivities[0])
    let leadsContentExists = typeof leadActivities !== 'undefined' && leadActivities.length > 0;
    if(leadsContentExists) {
      setLeadsExist(true)
      setLeadsLoading(false)
    }
    const timer = setTimeout(() => {
      leadsContentExists = typeof leadActivities !== 'undefined' && leadActivities.length > 0;
      if(leadsContentExists) {
        setLeadsExist(true)
        setLeadsLoading(false)
      } else {
        setLeadsLoading(false)
      }
    }, 7000);
    return () => clearTimeout(timer);
  }, [leadActivities])

  return (
    <div className="container__wrap overflow-scroll">
    <Container className="dashboard">
      <PageTooltip state={{ refreshTime: [refreshTime, setRefreshTime] }}/>
      <Row className="row__dashboard-intro">
        <Col md={12} className="dashboard-intro">
          <h3 className="page-title dashboard-title">Leads [2.0 DEV]</h3>
          <p className="dashboard-subtitle">Review lead activity to drive conversations with your donors. Select a lead from the table to view their activity.</p>
        </Col>
      </Row>
      <Row>
        {
          leadsExist ? (
            <LeadsContent key={refreshTime} leadsExist={leadsExist}/> 
          ) : leadsLoading ? (
            <LoadingIcon />
          ) : (
            <p style={{padding: 23}}>No identified leads exist yet. Leads will appear after form submissions or website visits from Stelter email campaigns.</p>
          )
        }
        
      </Row>
    </Container>
    </div>
  );
};

export default Page;
